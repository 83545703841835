@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  scroll-margin: 100px 0 0 0;
}

body {
  font-family: 'Poppins', sans-serif;

}

.custom_container {
  padding: 0 100px;
  margin: 0 auto;
}


@media only screen and (max-width: 600px) {
  .custom_container {
    padding: 0 20px;
    margin: 0 auto;
  }

}

@media only screen and (min-width: 1200px) {
  .custom_container {
    width: 1200px;
    left: 0;
    right: 0;
    padding: 0;
  }

}